<template>
  <div class="user-profile">
    <r-container>
      <r-form v-model="valid">
        <r-row>
          <r-col class="col-12">
            <r-btn icon text :to="{ name: 'base' }">
              <r-icon v-html="$r.icons.arrow_left"></r-icon>
            </r-btn>
            <span class="title">{{ $t("edit_profile",'renusify') }}</span>
          </r-col>
          <r-col class="col-12 text-center">
            <r-avatar size="200" editable="user/img/profile">
              <r-img
                :src="`/api/user/img/profile/${$r.store.user.info.id}`"
                alt="user profile"
                width="200"
                :query="`r=${Math.random()}&`"
                height="200"
              ></r-img>
            </r-avatar>
          </r-col>
          <r-col class="col-12">
            <r-text-input
              v-model="info.name"
              :label="$t('name','renusify')"
            ></r-text-input>
          </r-col>
          <r-col class="col-12">
            <r-text-input
              v-model="info.family"
              :label="$t('family','renusify')"
            ></r-text-input>
          </r-col>
          <r-col class="col-12">
            <r-unique-feild
              link="user/ckeck/username"
              v-model="info.username"
              :label="$t('username','renusify')"
            ></r-unique-feild>
          </r-col>
          <r-col class="col-12">
            <r-password
              v-model="info.password"
              :label="$t('password','renusify')"
              upperCase
              specialChar
            ></r-password>
          </r-col>
          <r-col class="col-12">
            <r-btn
              :loading="loading"
              :disabled="!valid"
              @click.prevent="send"
              block
              rounded
              :class="{
                'color-gradient-one-45': valid
              }"
              >{{ $t("send",'renusify') }}
            </r-btn>
          </r-col>
        </r-row>
      </r-form>
    </r-container>
  </div>
</template>

<script>
export default {
  name: "profile",
  data() {
    return {
      show: false,
      loading: false,
      valid: false,
      info: {}
    };
  },
  created() {
    this.$axios.get("user/getInfo").then(({ data }) => {
      this.info = data;
      this.info.password = null;
      this.show = true;
    });
  },
  methods: {
    send() {
      this.loading = true;
      this.$axios
        .put("user/updateInfo", {
          name: this.info.name,
          family: this.info.family,
          username: this.info.username,
          password: this.info.password
        })
        .then(
          () => {
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    }
  }
};
</script>

<style lang="scss">
@import "~renusify/style/include";
</style>
